

require('./bootstrapAuth');

require('trumbowyg');
require('trumbowyg/plugins/colors/trumbowyg.colors');
require('trumbowyg/dist/langs/fr.min');
require('stupid-table-plugin');


/**
 * jQuery
 */

import 'jquery-ui/ui/widgets/sortable.js';
import 'jquery-ui/ui/widgets/datepicker.js';


/**
 * Vue
 */
window.Vue = require('vue');

import VueAxios from 'vue-axios';
import axios from 'axios';
Vue.use(VueAxios, axios);


import fr from 'vee-validate/dist/locale/fr';
const dictionary = {
    fr: {
        messages: {
            /*
            required : () => 'Un petit oubli ?',
            alpha : () => 'Que des lettres ;)',
            email: () => 'ah ! il n\'y aurait pas une petite erreur dans votre email?',
            */
            url: () => 'La valeur entrée n\'est pas correcte, format attendu : "http://www.votresite.com"',
            email: () => 'La valeur entrée n\'est pas correcte, format attendu : "roger@votresite.com"',
            required: ()=> 'Ce champs est obligatoire',
            regex: ()=> ''
        }
    }
};
import VeeValidate, { Validator} from 'vee-validate';
// Validator.localize('fr', dictionary.fr);

Vue.use(VeeValidate, {
    // i18n,
    dictionary: {
        fr
    },
    classes: true,
    classNames: {
        valid: 'is-valid', // model is valid
        invalid: 'is-invalid', // model is invalid
    },
    locale : 'fr',
    validity:false,
    mode: 'eager'
});
VeeValidate.Validator.extend('sanitize_phone', {
// +32 2 390 00 21
// +32 471 90 42 95
// +32 67 87 44 80
//     getMessage: field => `Le format doit être : +32 2 123 45 67 | +32 471 12 34 56 | +32 81 12 34 56`,
    getMessage: field => `Le format doit être : 021234567 ou 081326598`,
    validate: value => {
        // const regex = /^([+][0-9]{2}\s[0-9]{1,3}\s[0-9]{2,3}\s[0-9]{2}\s[0-9]{2})$/;
        const regex = /^([0]{1}[0-9]{8,12})$/;
        return  regex.test(value);
    }
});

VeeValidate.Validator.extend('bce', {
    getMessage: field => `Le format doit être : 123456789 ou 0123456789`,
    validate: value => {
        const regex = /^([0]?[0-9]{9})$/;
        return  regex.test(value);
    }
});

import { MediaQueries } from '@wearelucid/vue-mq'
const breakpoints = {
    /*
    xs: 575,
    sm: 767,
    md: 991,
    lg: 1199,
    xl: Infinity,
    */
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
}
Vue.use(MediaQueries, { breakpoints })